/* Container styles */
.container {
    padding: 20px;
    max-width: 600px;
    margin: auto;
}

/* Profile list styles */
.profileList {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
}

.profileItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

/* Button styles */
.button {
    margin-left: 10px;
    padding: 5px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.profileSquare {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    margin: 10px;
    position: relative;
    cursor: pointer;
    border: 1px solid #ccc;
    transition: background-color 0.3s;
    border-radius: 30px; /* Increased border radius */
    position: relative;
    padding-bottom: 20px; /* Space for the profile name */
}

.profileSquare:hover {
    background-color: #f5f5f5;
}

.profileLabel {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    color: white; /* or any desired color that contrasts with the background */
    font-weight: bold;
}


.trashBinIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    z-index: 2;
    cursor: pointer;
}
.profileIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0.3;
}

.button:hover {
    background-color: #0056b3;
}

/* Other sections */
.otherSections {
    margin-top: 20px;
}

.inputField {
    padding: 5px;
    margin-right: 10px;
}

.marginBottom {
    margin-bottom: 20px; /* Adjust this value based on your design preference */
}