/* src/components/CostOfGoodsSold.module.css */

.tableContainer {
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
    font-size: 0.8rem;  /* Adjust font size */
}

.table th,
.table td {
    text-align: left;
    padding: 0.5rem;  /* Adjust padding */
    border: 1px solid #ddd;
}

.input {
    width: 5rem;
    padding: 0.25rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}


.totalsRow {
    background-color: #bdc3c7;
    border-bottom-style: groove;
    font-weight: bold;
}

.placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    color: #888;
}
