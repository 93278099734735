/* src/components/AnalyzerSidebar.module.css */
:root {
    --sidebar-width: 15%;
}
.sidebar {
    width: var(--sidebar-width);
    transition: transform 0.3s ease-in-out;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    margin-right: 1rem;
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
}

.uploadSection,
.filterSection {
    margin-bottom: 20px;
}

.fileInput_input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.exportSection {
    display: flex;
    flex-direction: column;
    gap: 10px;  /* creates a space between the buttons */
}

.exportButton {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007BFF;  /* Example color; adjust as necessary */
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;  /* space between icon and text */
    transition: background-color 0.3s ease-in-out;
}

.exportButton:hover {
    background-color: #0056b3;  /* Darker blue on hover; adjust as necessary */
}

@media screen and (max-width: 1300px){
    .sidebar{
        flex-direction: row;
        width: 100%;
    } 
    .exportSection{
        width: 100%;
        flex-direction: row;
        justify-content: center;
    }
}

.operationTypeSection {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
}

.operationType {
    padding: 10px 15px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s, border-color 0.2s;
    text-align: center;
}

.operationType:hover {
    background-color: #052c57;
    border-color: #ffffff;
    color:white;
}

.selected {
    background-color: #007BFF; /* Change to your preferred color */
    color: #fff; /* White text on the blue background */
    border-color: #007BFF;
}

/* If you want to remove the margin-bottom for the last item */
.operationType:last-child {
    margin-bottom: 0;
}