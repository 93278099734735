/* Analyzer.module.css */
:root {
    --sidebar-width: 300px; /* Adjust this value to change the sidebar width */
}

.container {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
}



.sidebar,
.sidebarHidden {
    width: var(--sidebar-width);
    transition: transform 0.3s ease-in-out;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
}

.sidebarHidden {
    transform: translateX(calc(-1 * var(--sidebar-width)));
    visibility: hidden;
}



.mainContent,
.mainContentWithSidebar {
    flex-grow: 1;
    transition: margin-left 0.3s ease-in-out;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    overflow: auto;
}

.mainContentWithSidebar {
    margin-left: var(--sidebar-width);
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
}

.uploadSection,
.filterSection {
    margin-bottom: 20px;
}

.fileInput_input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.toggleSidebarButton {
    background-color: #f0f0f0;  /* Adjust as needed */
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 10px; /* Additional spacing */
}


/* Additional styling for the icons */
.fa-upload,
.fa-filter,
.fa-table {
    margin-bottom: 10px;
    color: #333;
}




@media screen and (min-width: 1301px){
    .sidebar {
        flex-direction: column;
        width: 30%;
    }
}
@media screen and (max-width: 1300px){
    .container {
        flex-direction: column;
    }
    .sidebar{
        flex-direction: row;
        width: 100%;
    }   
}