.container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    border: 1px solid #e0e0e0;
    padding: 10px;
}

.select {
    width: 100%;
}
