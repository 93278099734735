.filterContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    margin-left: 5px; 
    margin-right: 5px;
}

.filterSelection {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    width: 100%;
}

.filterLabel {
    margin-right: 10px;
}

.customSelect {
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    background: #fff url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5H7z"/></svg>') no-repeat right 0.5rem center;
    background-size: 1.5rem;
    flex-grow: 1;
}

.select {
    width: 100%;
    border: none;
    padding: 10px;
    background: transparent;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.dateRange {
    margin-bottom: 10px;
    display: flex;
    align-items: left;
    flex-direction: column;
}

.dateLabel {
    margin-right: 10px;
}

.dateInput {
    margin-right: 5px;
    margin-left: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
}

.dash {
    margin: 0 5px;
}

.applyButton {
    display: flex;
    width: 100%;
    justify-content: center;
}

.button {
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    background-color: #008CBA;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.button:hover {
    background-color: #006699;
}

@media screen and (max-width: 1300px){
    .dateRange{
        flex-direction: row;
        width: 50%;
    } 

    .filterSelection{
        width: 50%;
    }

    .applyButton{
        width: 50%;
    }

    .filterContainer{
        flex-direction: row;
    }
}
