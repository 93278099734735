.mappingsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Here 200px is the minimum width of a column */
    gap: 10px; /* Gap between columns */
}

/* Optional: Adjustments for larger screens */
@media (min-width: 768px) {
    .mappingsGrid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media (min-width: 1024px) {
    .mappingsGrid {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}
