a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.profile-name {
  padding: 8px 12px;
  background-color: #e9ecef;
  border-radius: 4px;
  align-self: center;
  margin-left: 5px;
}

.navbar-container {
    display: flex;
    align-items: center;
}

.nav-item{
  margin: auto;
}